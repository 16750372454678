import { css } from '@emotion/react';
import { Types } from '@scorenco/core';

import { Markdown, Wrapper } from '../../primitives';

type TextSliceProps = {
  slice: Types.ComponentSlicesText;
};

export const TextSlice = ({ slice }: TextSliceProps) => {
  return (
    <Wrapper
      css={(theme) => css`
        margin: ${theme.spacing(2)} auto;
        text-align: ${slice.mode === 'article' ? 'left' : 'center'};
        max-width: ${theme.breakpoints.values.md}px;

        ${slice.mode !== 'article'
          ? `
        .MuiTypography-body1,
        .MuiTypography-body2,
        .markdown-list ul,
        .markdown-list ol {
          max-width: ${theme.breakpoints.values.sm}px;
          padding-left: 0;
          margin-left: auto;
          margin-right: auto;
        }
        `
          : ''}
      `}
    >
      <Markdown value={slice.content} />
    </Wrapper>
  );
};
